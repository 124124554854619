





















import { Component, Vue } from "vue-property-decorator";
import CamiloDetail from "@/components/CamiloDetail.vue";
import SyntheticDialog from "@/components/SyntheticDialog.vue";
import CamiloCode from "@/components/CamiloCode.vue";
import SupplyPackageApi, { IEntitySupply } from "@/api/supply-package.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { Skeleton } from "vant";
import { observer } from "@/utils/common.util";

@Component({
  components: {
    SyntheticDialog,
    CamiloCode,
    CamiloDetail,
    Skeleton,
  },
})
export default class MyVoucherEntityDetail extends Vue {
  globalState = getModule(GlobalStateStore);
  id = Number(this.$route.params.id);
  entityDetail: IEntitySupply | null = null;

  dialog = false;
  skeleton = true;

  async handleRefreshData() {
    this.skeleton = true;
    this.entityDetail = await SupplyPackageApi.getEntitySupplyDetail(this.id);
    this.skeleton = false;
  }

  get isLogin(): boolean {
    return Boolean(this.globalState.userToken);
  }

  mounted(): void {
    observer("refreshInit", () => this.handleRefreshData());
    this.handleRefreshData();
  }
}
